import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Box, styled } from '@mui/system';
import { FlexBox } from 'utils/styledComponents';
import InputField from 'components/common/InputField';
import { Divider, Typography } from '@mui/material';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import CommonButton from 'components/common/CommonButton';
import { acterioTheme, primary } from 'theme';
import Button from '@mui/material/Button';
import { useApplicationContext } from 'services/contexts/Applications/ApplicationsContext';
import {
  ApplicationQuestionTypes,
  IApplicantProcessEditMode,
  IApplicationTemplate,
} from 'utils/models';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { ApplicationBuilderQuestions } from './ApplicationBuilderQuestions';
import usePostNewApplication from 'hooks/Applications/usePostNewApplication';
import InformationModal from 'components/common/Modal/InformationModal';
import { Error } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from 'components/common/Loading';
import {
  getStoredEditedApplication,
  removeStoredEditedApplication,
  removeStoredNewApplication,
  STORAGE_KEYS,
  storeEditedApplication,
} from 'utils/storage';
import ApplicationBuilderStaticQuestion from './ApplicationBuilderStaticQuestion';
import { DatePickerStyled } from './ApplicationDatePicker';
import useFetchProcess from '../../../hooks/Applications/useFetchProcess';
import usePutProcess from '../../../hooks/Applications/usePutProcess';
import WarningDeleteModal from './WarningDeleteModal';
import ModalWithLink from '../../common/Modal/ModalWithLink';

export const ControlButtonStyled = styled(Button)({
  color: primary.dark,
  minWidth: '2.8rem',
  ':hover': {
    backgroundColor: 'transparent',
  },
});

const MAX_DESCRIPTION_LENGTH = 5000;

interface IApplicationBuilderProps {
  isEdit?: boolean;
}

const defaultTemplate: IApplicationTemplate = {
  id: null,
  name: '',
  description: '',
  startDate: null,
  endDate: null,
  sections: [
    {
      name: 'First section',
      questions: [
        {
          question: 'First question',
          description: '',
          type: ApplicationQuestionTypes.TEXT,
        },
      ],
    },
  ],
  submissionMessage: '',
};

const ApplicationBuilder: FunctionComponent<IApplicationBuilderProps> = (
  props,
) => {
  const { isEdit } = props;

  const {
    newApplicationSchema,
    setNewApplicationSchema,
    selectedApplicationId,
    isDuplicate,
    setIsDuplicate,
  } = useApplicationContext();

  const navigate = useNavigate();
  const { ecosystemName } = useParams();

  const { form: formToEdit, isProcessLoading: isEditLoading } = useFetchProcess(
    {
      id: selectedApplicationId,
    },
  );

  let applicantsInProgress = 0;

  if (formToEdit) {
    applicantsInProgress = formToEdit.applicantsInProgress;
  }

  const ref = useRef<HTMLInputElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isCancelModalOpen, setCancelIsModalOpen] = useState(false);
  const [isSuccessfulModalOpen, setIsSuccessfulModalOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [focusedSectionId, setFocusedSectionId] = useState<number>();
  const [newSections, setNewSections] = useState<number[]>([]);

  const [sharableLink, setSharableLink] = useState('');
  const [descriptionCharCount, setDescriptionCharCount] = useState(0);
  const [submissionMessageCharCount, setSubmissionMessageCharCount] =
    useState(0);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  const postNewApplication = usePostNewApplication({
    newApplicationSchema,
    setIsLoading,
    setSharableLink,
    setIsOpenModal: setIsSuccessfulModalOpen,
  });

  const putEditedApplication = usePutProcess({
    newApplicationSchema,
    setIsLoading,
    setIsOpenModal: setIsSuccessfulModalOpen,
    id: selectedApplicationId,
    defaultTemplate,
  });

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    resetField,
    setError,
    clearErrors,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IApplicationTemplate>({
    defaultValues: newApplicationSchema || defaultTemplate,
  });

  const formData = watch();

  useEffect(() => {
    setNewApplicationSchema(formData as IApplicationTemplate);
  }, [formData]);

  useEffect(() => {
    if (formToEdit) {
      setApplicationFormField(formToEdit);
      storeEditedApplication(formToEdit, selectedApplicationId as number);
    }
    return () => {
      reset(defaultTemplate);
      removeStoredEditedApplication(selectedApplicationId as number);
    };
  }, [formToEdit]);

  const {
    fields: sectionFields,
    append: appendSection,
    remove: removeSection,
  } = useFieldArray({
    name: 'sections',
    control,
  });

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDescriptionCharCount(event.target.value.length);
  };

  function clickAwayToUpdateForm(
    submitCallback: SubmitHandler<IApplicationTemplate>,
  ) {
    return (data: IApplicationTemplate) => {
      setTimeout(() => {
        if (ref && ref.current) {
          ref.current.click();
        }
        submitCallback(data);
      }, 1);
    };
  }

  const onAppFormSubmit: SubmitHandler<IApplicationTemplate> = function (
    data: IApplicationTemplate,
  ): void {
    if (!isEdit || (isEdit && isDuplicate)) {
      postNewApplication().then(() => {
        localStorage.removeItem(STORAGE_KEYS.NEW_APPLICATION);
      });
    } else {
      putEditedApplication();
    }
  };

  function onCancelHandler() {
    reset(defaultTemplate);
    removeStoredNewApplication();
  }

  function onRevertHandler() {
    const revertedForm = getStoredEditedApplication(
      selectedApplicationId as number,
    );
    if (!revertedForm) return;
    setApplicationFormField(revertedForm);
  }

  function setApplicationFormField(form: IApplicantProcessEditMode) {
    const startDate = new Date(form.startDate);
    const endDate = new Date(form.endDate);
    reset({
      name: isDuplicate ? '' : (form.name as string),
      description: form.description as string,
      startDate: isDuplicate ? null : startDate,
      endDate: isDuplicate ? null : endDate,
      sections: form.applicationTemplate.sections,
      submissionMessage: form.submissionMessage,
    });

    if (isDuplicate) {
      setError('name', {
        type: 'manual',
        message: 'Please enter application name.',
      });
      setError('startDate', {
        type: 'manual',
      });
      setError('endDate', {
        type: 'manual',
      });
    }
  }

  function renderBasicSection() {
    return (
      <>
        <FlexBox
          sx={{
            gap: '1.25rem',
          }}>
          <h2>Application builder</h2>
          <img
            src={'/images/placeholders/beta.png'}
            alt={'beta'}
            style={{
              maxWidth: '5rem',
            }}
          />
        </FlexBox>

        <FlexBox
          className={'startDate'}
          sx={{
            gap: '2.5rem',
            alignItems: errors.name ? 'center' : 'end',
          }}>
          <FlexBox
            sx={{
              minWidth: '40%',
              maxWidth: '37.5rem',
            }}>
            <InputField
              {...register('name', {
                required: 'Please enter application name.',
              })}
              onChange={() => clearErrors('name')}
              placeholder={'Create a name for the application'}
              maxLength={255}
              label={'Application name*'}
              helperText={errors.name && errors.name.message}
              helperIcon={errors.name && 'warning'}
              error={!!errors.name}
              formLabelStyles={{
                marginTop: 0,
              }}
            />
          </FlexBox>

          <FlexBox
            sx={{
              gap: '1.25rem',
            }}>
            <Typography>Start date*</Typography>

            <Controller
              control={control}
              name={'startDate'}
              rules={{ required: 'Please, enter start date' }}
              render={({ field }) => {
                return (
                  <DatePickerStyled
                    value={field.value}
                    inputRef={field.ref}
                    errors={errors.startDate}
                    maxDate={endDate}
                    onChange={(date: Date) => {
                      if (!date) {
                        field.onChange(date);
                        setEndDate(date);
                        return;
                      }
                      const utcDate = new Date(
                        Date.UTC(
                          date.getFullYear(),
                          date.getMonth(),
                          date.getDate(),
                        ),
                      );
                      field.onChange(utcDate);
                      setStartDate(utcDate);
                      clearErrors('startDate');
                    }}
                  />
                );
              }}
            />

            <Typography>End date*</Typography>

            <Controller
              control={control}
              name={'endDate'}
              rules={{ required: 'Please, enter end date' }}
              render={({ field }) => {
                return (
                  <DatePickerStyled
                    value={field.value}
                    inputRef={field.ref}
                    errors={errors.endDate}
                    minDate={startDate}
                    onChange={(date: Date) => {
                      if (!date) {
                        field.onChange(date);
                        setEndDate(date);
                        return;
                      }
                      const utcDate = new Date(
                        Date.UTC(
                          date.getFullYear(),
                          date.getMonth(),
                          date.getDate(),
                        ),
                      );
                      field.onChange(utcDate);
                      setEndDate(utcDate);
                      clearErrors('endDate');
                    }}
                  />
                );
              }}
            />
          </FlexBox>
        </FlexBox>

        <Divider
          sx={{
            height: '2px',
            marginTop: '1.875rem',
          }}
        />

        <Box
          sx={{
            position: 'relative',
          }}>
          <InputField
            {...register('description')}
            label={'Description'}
            placeholder={'Here you can write about the application...'}
            multiline
            onChange={handleDescriptionChange}
            maxLength={MAX_DESCRIPTION_LENGTH}
          />
          <Typography
            sx={{
              display:
                descriptionCharCount < MAX_DESCRIPTION_LENGTH * 0.75
                  ? 'none'
                  : 'block',
              position: 'absolute',
              bottom: '0.125rem',
              right: '7px',
              color: 'rgba(0, 0, 0, 0.5)',
            }}
            variant='body2'>
            {descriptionCharCount} / {MAX_DESCRIPTION_LENGTH}
          </Typography>
        </Box>
      </>
    );
  }

  const renderButtons = () => {
    return (
      <FlexBox
        sx={{
          gap: '1.5rem',
        }}>
        <CommonButton
          onClick={() => setCancelIsModalOpen(true)}
          text={'Cancel'}
          bgcolor={'transparent'}
          color={primary.dark}
          sx={{
            width: '12.5rem',
            height: '3rem',
          }}
        />
        {renderCancelModal()}
        <CommonButton
          onClick={onRevertHandler}
          sx={{
            width: '12.5rem',
            height: '3rem',
            display: isEdit && !isDuplicate ? 'block' : 'none',
          }}
          text={isEdit ? 'Revert changes' : 'Save as draft'}
          bgcolor={primary.light}
          color={primary.dark}
        />
        <CommonButton
          submit
          sx={{
            width: '12.5rem',
            height: '3rem',
          }}
          text={
            isEdit && !isDuplicate
              ? 'Save changes'
              : isDuplicate
              ? 'Create duplicate'
              : 'Start application'
          }
          bgcolor={primary.dark}
        />
        <ModalWithLink
          isOpen={isSuccessfulModalOpen}
          handleClose={() => setIsSuccessfulModalOpen(false)}
          primaryText='Go to applications'
          primaryOnClick={() => window.location.reload()}
          secondaryText={isEdit && !isDuplicate ? 'Keep editing' : 'Cancel'}
          secondaryOnClick={() => setIsSuccessfulModalOpen(false)}
          headerText={
            isEdit && !isDuplicate
              ? 'Changes saved successfully!'
              : isDuplicate
              ? 'Application was duplicated!'
              : 'Your application form has been successfully created!'
          }
          aboveLinkText={
            !isEdit || (isEdit && isDuplicate)
              ? 'You can access your form using the following link:'
              : ''
          }
          link={sharableLink}
          setLink={setSharableLink}
          underLinkText={
            isEdit && !isDuplicate
              ? 'Your changes have been saved. Moving forward, all application forms will reflect these updates. Every applicant will now see and complete the form in its updated format.'
              : isDuplicate
              ? 'Your application was duplicated! You can find it in the list of application processes.'
              : 'You can always find these options later by clicking the "More Options" icon on your application card.'
          }
        />
      </FlexBox>
    );
  };

  const renderCancelModal = () => {
    if (!isCancelModalOpen) return;
    return (
      <InformationModal
        isOpen={isCancelModalOpen}
        handleClose={() => setCancelIsModalOpen(false)}
        primaryText='Leave'
        primaryOnClick={() => {
          onCancelHandler();
          window.location.reload();
          navigate(`/ecosystem/${ecosystemName}/admin/applications`);
          setIsDuplicate(false);
        }}
        primaryBgColor={acterioTheme.warningRed}
        primaryBgHoverColor={acterioTheme.warningPlaceholder}
        secondaryText='Cancel'
        secondaryOnClick={() => setCancelIsModalOpen(false)}
        headerText={'Are you sure you want to leave application builder?'}
        buttonSx={{
          '&:hover': {
            backgroundColor: primary.warningHover,
            color: primary.warningRed,
          },
        }}>
        <>
          <Box sx={{ display: 'flex' }}>
            <Error
              sx={{ marginRight: '0.5rem', color: acterioTheme.warningRed }}
            />
            <Box>
              Leaving application builder result in lost of any data entered.
              Please confirm your choice carefully.
            </Box>
          </Box>
        </>
      </InformationModal>
    );
  };

  function renderStaticQuestions() {
    return (
      <Box>
        <FlexBox
          sx={{
            marginBottom: '1.25rem',
            ml: '3rem',
          }}>
          <InputField
            ref={ref}
            width={'31.25rem'}
            readonly
            sx={{
              '& .MuiOutlinedInput-root': {
                opacity: '200%',
                '& .MuiInputBase-input': {
                  WebkitTextFillColor: primary.mainBlackFont,
                },
              },
            }}
            value={'Basic information - questions that can not be edited'}
          />
        </FlexBox>
        <ApplicationBuilderStaticQuestion text='Applicant name' number={1} />
        <ApplicationBuilderStaticQuestion text='Company name' number={2} />
        <ApplicationBuilderStaticQuestion text='Email address' number={3} />
      </Box>
    );
  }

  const handleAddSection = () => {
    const newSectionIndex = sectionFields.length;
    appendSection({
      name: '',
      questions: [
        {
          question: '',
          description: '',
          type: ApplicationQuestionTypes.TEXT,
        },
      ],
    });
    setNewSections((prevSections) => [...prevSections, newSectionIndex]);
  };

  if (isLoading) return <Loading />;

  if (isEdit && isEditLoading) return <Loading />;

  return (
    <Box
      sx={{
        padding: '3.125rem',
        maxWidth: '100%',
      }}>
      <WarningDeleteModal
        isWarningModalOpen={isWarningModalOpen}
        setIsWarningModalOpen={setIsWarningModalOpen}
        actionOnConfirm={() => {
          removeSection(focusedSectionId);
          setIsWarningModalOpen(false);
        }}
      />
      <form onSubmit={handleSubmit(clickAwayToUpdateForm(onAppFormSubmit))}>
        {renderBasicSection()}

        <Box>
          <h2>Create questions and customize type</h2>

          {renderStaticQuestions()}

          {sectionFields.map((section, sectionIndex) => (
            <Box
              key={section.id}
              sx={{
                marginTop: '1.25rem',
              }}>
              <FlexBox
                sx={{
                  marginBottom: '1.25rem',
                }}>
                <ControlButtonStyled
                  sx={{
                    '& .MuiSvgIcon-root, .MuiBox-root': {
                      transition: 'all 0.3s ease-in-out',
                    },
                    ':hover': {
                      '& .MuiSvgIcon-root': {
                        color: primary.pureWhite,
                      },
                      '& .MuiBox-root': {
                        backgroundColor: primary.dark,
                      },
                    },
                  }}
                  onClick={handleAddSection}>
                  <Box
                    sx={{
                      border: '1px solid black',
                      borderRadius: '100%',
                      lineHeight: '0.75',
                      backgroundColor: primary.pureWhite,
                    }}>
                    <AddIcon
                      sx={{
                        fontSize: '2rem',
                      }}
                    />
                  </Box>
                </ControlButtonStyled>
                <InputField
                  {...register(`sections.${sectionIndex}.name`, {
                    required:
                      sectionIndex !== 0 && 'Please, enter a name of section',
                  })}
                  helperText={errors.sections && errors.sections.message}
                  helperIcon={errors.sections && 'warning'}
                  error={!!errors?.sections?.[sectionIndex]?.name}
                  width={'31.25rem'}
                  maxLength={255}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      opacity: sectionIndex === 0 ? '200%' : '100%',
                      '& .MuiInputBase-input': {
                        WebkitTextFillColor: primary.mainBlackFont,
                      },
                    },
                  }}
                />
                <ControlButtonStyled
                  disabled={sectionIndex === 0}
                  onClick={() => {
                    if (applicantsInProgress > 0) {
                      setFocusedSectionId(sectionIndex);
                      setIsWarningModalOpen(true);
                    } else {
                      removeSection(sectionIndex);
                    }
                  }}>
                  <CloseIcon />
                </ControlButtonStyled>
              </FlexBox>

              <ApplicationBuilderQuestions
                resetField={resetField}
                formData={formData as IApplicationTemplate}
                errors={errors}
                register={register}
                control={control}
                sectionIndex={sectionIndex}
                applicantsInProgress={applicantsInProgress}
                newSections={newSections}
                setValue={setValue}
                getValues={getValues}
                isEdit={isEdit}
              />
            </Box>
          ))}

          <FlexBox sx={{ position: 'relative' }}>
            <InputField
              {...register('submissionMessage')}
              label={'Submission message'}
              maxLength={255}
              onChange={(event) => {
                setSubmissionMessageCharCount(event.target.value.length);
              }}
              placeholder={
                "Enter a message to display upon submission e.g. 'Thank you for applying'"
              }
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& .MuiInputBase-input': {
                    paddingRight: '5.5rem',
                  },
                },
              }}
            />
            <Typography
              sx={{
                position: 'absolute',
                bottom: '0.425rem',
                right: '7px',
                color: 'rgba(0, 0, 0, 0.5)',
              }}
              variant='body2'>
              {submissionMessageCharCount} / 255
            </Typography>
          </FlexBox>
        </Box>

        <FlexBox
          className={'end'}
          sx={{
            marginTop: '2.2rem',
          }}>
          {renderButtons()}
        </FlexBox>
      </form>
    </Box>
  );
};

export default ApplicationBuilder;
