import { Box } from '@mui/system';
import React, { FunctionComponent, useContext, useState } from 'react';
import { useParams } from 'react-router';
import { Typography, useTheme } from '@mui/material';
import { IMeeting } from 'utils/models';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import {
  MEETING_CANCEL_MENTOR,
  MEETING_CANCEL_USER,
  deleteData,
} from 'utils/requests';
import ConfirmationForm from 'components/common/ConfirmationForm';
import { FlexBox } from '../../../utils/styledComponents';
import AccessLevelTagCard from '../../common/AccessLevelTagCard';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { colorPallete, naturalPallete } from '../../../theme';
import { Email, LocationOn, Phone } from '@mui/icons-material';
import CommonButton from '../../common/CommonButton';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';

interface MeetingProps {
  meeting: IMeeting;
  setMeetings: (meetings: any) => void;
  isAttendingCalendar: boolean;
}

const Meeting: FunctionComponent<MeetingProps> = (props) => {
  const { meeting, setMeetings, isAttendingCalendar } = props;
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);
  const theme = useTheme();
  const navigate = useNavigate();

  const [isShowConfirmForm, setIsShowConfirmForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const user = isAttendingCalendar ? meeting.bookedUser : meeting.bookingUser;

  function viewProfile() {
    navigate(`/ecosystem/${ecosystemName}/members/${user.id}`);
  }

  async function requestCancelMeeting() {
    setIsLoading(true);

    try {
      await deleteData(
        isAttendingCalendar ? MEETING_CANCEL_USER : MEETING_CANCEL_MENTOR,
        [
          { name: 'ecosystemName', value: ecosystemName },
          { name: 'meetingId', value: meeting.id },
          { name: 'id', value: 'The meeting has been canceled by the user.' },
        ],
      );

      setMeetings((prevMeetings: IMeeting[]) =>
        prevMeetings.filter((m) => m.id !== meeting.id),
      );

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Meeting canceled successfully',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  function renderHeader() {
    return (
      <FlexBox
        sx={{
          alignItems: 'start',
          gap: '0.625rem',
        }}>
        <img
          src={user.profilePic}
          style={{
            width: '3.75rem',
            height: '3.75rem',
            objectFit: 'cover',
            borderRadius: '100%',
          }}
          alt='Profile picture'
        />
        <FlexBox
          sx={{
            alignItems: 'start',
            flexDirection: 'column',
          }}>
          <Typography
            variant={'h6'}
            sx={{
              lineHeight: '1rem',
            }}>
            {user.fullName}
          </Typography>
          <Typography variant={'h6'} color={theme.palette.primary.main}>
            {user.primaryCompany}
          </Typography>
        </FlexBox>

        <AccessLevelTagCard
          accessLevelName={user.accessLevelName}
          accessLevelColor={user.accessLevelColor}
          height={'1.25rem'}
        />
      </FlexBox>
    );
  }

  function renderMeetingDetails() {
    return (
      <FlexBox
        sx={{
          width: '100%',
          justifyContent: 'space-between',
        }}>
        <FlexBox
          sx={{
            flexDirection: 'column',
            alignItems: 'start',
            gap: 0.625,
          }}>
          <FlexBox
            sx={{
              gap: 2,
            }}>
            <AccessTimeFilledIcon sx={{ color: theme.palette.primary.main }} />
            <Typography
              sx={{
                color: theme.palette.primary.dark,
                bgcolor: colorPallete.additionalGreen,
                border: `1px solid ${colorPallete.additionalDarkGreen}`,
                borderRadius: '4px',
                paddingY: 0.3,
                paddingX: 1.2,
              }}>
              {`${meeting.startTime} - ${meeting.endTime}`}
            </Typography>
          </FlexBox>
          <FlexBox
            sx={{
              gap: 1,
            }}>
            <Phone sx={{ color: theme.palette.primary.main }} />
            <Typography
              sx={{
                paddingY: 0.3,
                paddingX: 1.2,
                maxWidth: 170,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}>
              {user.phone ?? 'No phone'}
            </Typography>
          </FlexBox>
          <FlexBox
            sx={{
              gap: 1,
            }}>
            <Email sx={{ color: theme.palette.primary.main }} />
            <Typography
              sx={{
                color: theme.palette.primary.dark,
                borderRadius: 0.6,
                paddingY: 0.3,
                paddingX: 1.2,
                fontSize: 12,
              }}>
              {user.emailEcoPreferred ? (
                <a href={`mailto:${user.emailEcoPreferred}`}>
                  {user.emailEcoPreferred}
                </a>
              ) : (
                'Email was not provided'
              )}
            </Typography>
          </FlexBox>
          <FlexBox>
            {meeting.virtualMeetingLink && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <LocationOn
                  sx={{ color: theme.palette.primary.main, marginRight: 1 }}
                />
                <Typography
                  sx={{
                    color: theme.palette.primary.dark,
                    borderRadius: 0.6,
                    paddingY: 0.3,
                    paddingX: 1.2,
                  }}>
                  <a href={meeting.virtualMeetingLink} target='_blank'>
                    Link to the meeting
                  </a>
                </Typography>
              </Box>
            )}
          </FlexBox>
        </FlexBox>

        <FlexBox
          sx={{
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'start',
            maxWidth: '26.75rem',
            overflow: 'hidden',
            wordWrap: 'break-word',
          }}>
          <Typography variant={'h6'}>{meeting.topic}</Typography>
          <Typography
            sx={{
              wordWrap: 'break-word',
            }}>
            {meeting.info}
          </Typography>
        </FlexBox>
      </FlexBox>
    );
  }

  function renderButtons() {
    return (
      <FlexBox
        sx={{
          width: '100%',
          gap: 1.25,
          justifyContent: 'end',
        }}>
        <CommonButton
          onClick={() => setIsShowConfirmForm(true)}
          color={theme.palette.primary.dark}
          bgcolor={theme.palette.primary.light}
          hoverColor={{
            color: naturalPallete.pureWhite,
            bgcolor: theme.palette.primary.dark,
          }}
          icon={<CancelIcon />}
          text={'cancel this meeting'}
          sx={{
            width: '13.5rem',
          }}
        />
        <CommonButton
          onClick={viewProfile}
          icon={<VisibilityIcon />}
          text={'View profile'}
        />
      </FlexBox>
    );
  }

  return (
    <FlexBox
      sx={{
        width: '56rem',
        height: '20.75rem',
        bgcolor: '#FFFFFF',
        borderRadius: '12px',
        marginY: 2,
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1.875rem 2.5rem',
        flexDirection: 'column',
        alignItems: 'start',
      }}>
      {renderHeader()}
      {renderMeetingDetails()}
      {renderButtons()}
      {isShowConfirmForm && (
        <ConfirmationForm
          isOpen={isShowConfirmForm}
          handleClose={() => {
            setIsShowConfirmForm(false);
          }}
          funcExe={requestCancelMeeting}
          text={'cancel this meeting?'}
          isLoading={isLoading}
        />
      )}
    </FlexBox>
  );
};

export default Meeting;
