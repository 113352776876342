import React, { useMemo, useState } from 'react';
import { Box } from '@mui/system';
import { FlexBox } from '../../../utils/styledComponents';
import { naturalPallete } from '../../../theme';
import { TypographyGrey } from '../../Admin/Applications/ApplicationsListView';
import BookingAnalyticsElement from './BookingAnalyticsElement';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import Button from '@mui/material/Button';
import { BookingStatsDto } from '../../../utils/models';

interface BookingAnalyticsListProps {
  bookingList: BookingStatsDto[];
}

const BookingAnalyticsList: React.FunctionComponent<
  BookingAnalyticsListProps
> = (props) => {
  const { bookingList } = props;

  const [sorting, setSorting] = useState<'ascending' | 'descending' | null>(
    null,
  );
  const [sortType, setSortType] = useState<'date' | 'time' | null>(null);

  function sortBy(data: BookingStatsDto[], sortType: string) {
    const sortedData = [...data];

    const comparator = (a: BookingStatsDto, b: BookingStatsDto) => {
      const factor = sorting === 'ascending' ? 1 : -1;

      if (sortType === 'date') {
        return (
          (new Date(a.date).getTime() - new Date(b.date).getTime()) * factor
        );
      } else {
        return (
          (Number(a.startTime.slice(0, 2)) - Number(b.startTime.slice(0, 2))) *
          factor
        );
      }
    };

    return sortedData.sort(comparator);
  }

  const sortedList = useMemo(() => {
    if (sorting && sortType) {
      return sortBy(bookingList, sortType);
    } else {
      return bookingList;
    }
  }, [sorting, bookingList, sortType]);

  const renderArrowButton = (type: 'date' | 'time') => {
    return (
      <Button
        sx={{
          minWidth: '0',
          width: '2rem',
          color: naturalPallete.natural1,
          '&:hover': {
            backgroundColor: naturalPallete.whiteHighlight,
          },
        }}
        onClick={() => {
          setSortType(type);
          setSorting((prev) => {
            return prev === 'ascending' ? 'descending' : 'ascending';
          });
        }}>
        <ArrowDropDownIcon
          sx={{
            transition: 'all 0.3s ease-in-out',
            transform: sorting === 'ascending' ? 'rotate(0.5turn)' : '',
          }}
        />
      </Button>
    );
  };

  function renderListView() {
    return (
      <Box
        sx={{
          padding: '0.75rem',
          border: '1px solid rgba(204, 204, 204, 0.2)',
          borderRadius: '12px',
          backgroundColor: naturalPallete.pureWhite,
        }}>
        <FlexBox
          sx={{
            borderBottom: `1px solid ${naturalPallete.natural6}`,
            paddingY: '1.25rem',
            paddingLeft: '2rem',
            gap: '1.875rem',
          }}>
          <FlexBox
            sx={{
              width: '13rem',
              justifySelf: 'start',
            }}>
            <TypographyGrey>BOOKED BY</TypographyGrey>
          </FlexBox>
          <FlexBox
            sx={{
              width: '20.313rem',
            }}>
            <TypographyGrey>BOOKED WITH</TypographyGrey>
          </FlexBox>
          <FlexBox
            sx={{
              width: '12.5rem',
            }}>
            <TypographyGrey>STATUS</TypographyGrey>
          </FlexBox>
          <FlexBox
            sx={{
              width: '8rem',
            }}>
            <TypographyGrey>DATE</TypographyGrey>
            {renderArrowButton('date')}
          </FlexBox>
          <FlexBox
            sx={{
              width: '8rem',
            }}>
            <TypographyGrey>TIME</TypographyGrey>
            {renderArrowButton('time')}
          </FlexBox>
        </FlexBox>

        <FlexBox
          sx={{
            minHeight: '100%',
            gap: '0.5rem',
            flexDirection: 'column',
          }}>
          {sortedList.map((item) => {
            return <BookingAnalyticsElement key={item.id} bookingData={item} />;
          })}
        </FlexBox>
      </Box>
    );
  }
  return <Box>{renderListView()}</Box>;
};

export default BookingAnalyticsList;
