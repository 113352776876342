import React, { useContext, useEffect, useMemo, useState } from 'react';
import BookingAnalyticsList from './BookingAnalyticsList';
import { Typography } from '@mui/material';
import { FlexBox } from '../../../utils/styledComponents';
import SelectDropdown from '../../common/SelectDropdown';
import { BOOKING_ANALYTICS, getData } from '../../../utils/requests';
import { useParams } from 'react-router-dom';
import { ALERT_TYPES, AlertContext } from '../../Alert/AlertContext';
import Loading from '../../common/Loading';
import { TypographyGrey } from '../../Admin/Applications/ApplicationsListView';
import { BookingStatsDto } from '../../../utils/models';

const BookingAnalytics = () => {
  const { ecosystemName } = useParams();
  const { addAlert } = useContext(AlertContext);

  const [bookingsList, setBookingsList] = useState<BookingStatsDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [availableBookedByOptions, setAvailableBookedByOptions] = useState<
    string[]
  >([]);
  const [availableBookedWithOptions, setAvailableBookedWithOptions] = useState<
    string[]
  >([]);
  const availableStatuses = ['Accepted', 'Pending', 'Declined'];

  const [filterByBookedBy, setFilterByBookedBy] = useState<
    string | undefined
  >();
  const [filterByBookedWith, setFilterByBookedWith] = useState<
    string | undefined
  >();
  const [filterByStatus, setFilterByStatus] = useState<
    'Accepted' | 'Pending' | 'Declined' | undefined
  >(undefined);

  async function requestForStats() {
    try {
      setIsLoading(true);
      const data = await getData(BOOKING_ANALYTICS, [
        { name: 'ecosystemName', value: ecosystemName },
      ]);
      setBookingsList(data);
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    requestForStats();
  }, []);

  //getting list of names from booking list so they will be available in selector options
  useEffect(() => {
    const bookedByNames = Array.from(
      new Set(bookingsList.map((item) => item.bookingUser.fullName)),
    );
    const bookedWithNames = Array.from(
      new Set(bookingsList.map((item) => item.bookedUser.fullName)),
    );

    setAvailableBookedByOptions(bookedByNames);
    setAvailableBookedWithOptions(bookedWithNames);
  }, [bookingsList]);

  //this function is only exists because we have separate values for one field. I don't really like it, but it is what it is
  function checkStatus(item: BookingStatsDto, filterByStatus: string) {
    if (filterByStatus === 'Pending') {
      return item.pending;
    }
    if (filterByStatus === 'Accepted') {
      return !item.pending && item.accepted;
    }
    if (filterByStatus === 'Declined') {
      return !item.pending && !item.accepted;
    }
  }

  //combining three filters in one function
  function filterData(data: BookingStatsDto[]) {
    {
      return data.filter((item) => {
        const matchesBookedBy = filterByBookedBy
          ? item.bookingUser.fullName.includes(filterByBookedBy)
          : true;
        const matchesBookedWith = filterByBookedWith
          ? item.bookedUser.fullName.includes(filterByBookedWith)
          : true;
        const matchesStatus = filterByStatus
          ? checkStatus(item, filterByStatus)
          : true;

        return matchesBookedBy && matchesBookedWith && matchesStatus;
      });
    }
  }

  //those two variables are just filtering picked name, so it won't appear in other list
  const filterBookedByOptions = useMemo(() => {
    return availableBookedWithOptions.filter(
      (name) => name !== filterByBookedWith,
    );
  }, [availableBookedWithOptions, filterByBookedWith]);

  const filterBookedWithOptions = useMemo(() => {
    return availableBookedByOptions.filter((name) => name !== filterByBookedBy);
  }, [availableBookedByOptions, filterByBookedBy]);

  const filteredBookingList = useMemo(
    () => filterData(bookingsList),
    [filterByBookedBy, filterByBookedWith, filterByStatus, bookingsList],
  );

  const renderFilters = () => {
    return (
      <FlexBox>
        <SelectDropdown
          value={filterByBookedBy}
          onChange={setFilterByBookedBy}
          options={filterBookedByOptions}
          height='2.5rem'
          placeholder={'Filter booking users'}
          sx={{ minWidth: '13rem', mx: '1rem' }}
        />
        <SelectDropdown
          value={filterByBookedWith}
          onChange={setFilterByBookedWith}
          options={filterBookedWithOptions}
          height='2.5rem'
          placeholder={'Filter booked users'}
          sx={{ minWidth: '13rem', mx: '1rem' }}
        />
        <SelectDropdown
          value={filterByStatus}
          onChange={setFilterByStatus}
          options={availableStatuses}
          height='2.5rem'
          placeholder={'Filter status'}
          sx={{ minWidth: '9rem', mx: '1rem' }}
        />

        <TypographyGrey>({filteredBookingList.length})</TypographyGrey>
      </FlexBox>
    );
  };

  return (
    <FlexBox
      sx={{
        flexDirection: 'column',
        alignItems: 'start',
        paddingTop: '2.5rem',
        gap: '1.875rem',
        width: '100%',
      }}>
      <Typography variant={'h4'}>Booking analytics</Typography>
      {renderFilters()}
      <FlexBox
        sx={{
          width: '100%',
        }}>
        {isLoading ? (
          <Loading />
        ) : (
          <BookingAnalyticsList bookingList={filteredBookingList} />
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default BookingAnalytics;
