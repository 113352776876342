import { Box } from '@mui/system';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getAccount } from 'utils/storage';
import { useNavigate, useParams } from 'react-router';
import BookingCalendar from './BookingCalendar';
import { IAvailableTimeslots } from 'utils/models';
import { AlertContext } from 'components/Alert/AlertContext';
import dayjs from 'dayjs';

interface CalendarPreviewProps {
  availability: IAvailableTimeslots[];
  setSelectedDay: (date: Date | null) => void;
  header?: string;
}

const StaticCalendar: FunctionComponent<CalendarPreviewProps> = (props) => {
  const { availability, setSelectedDay, header } = props;
  const { ecosystemName } = useParams();
  const navigate = useNavigate();
  const currentAccount = getAccount();
  const { addAlert } = useContext(AlertContext);

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const [monthIndex, setMonthIndex] = useState<number>(dayjs().month());
  const [monthNumber, setMonthNumber] = useState<number>(monthIndex);
  const [currentYear, setCurrentYear] = useState<number>(dayjs().year());

  useEffect(() => {
    setSelectedDay(selectedDate);
  }, [selectedDate]);

  function handleHourDateClick(day: Date) {
    return;
  }

  function renderCalendar() {
    return (
      <Box marginBottom={'1rem'}>
        <BookingCalendar
          availability={availability}
          handleHourDateClick={handleHourDateClick}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          monthIndex={monthIndex}
          monthNumber={monthNumber}
          currentYear={currentYear}
          setMonthIndex={setMonthIndex}
          setCurrentYear={setCurrentYear}
          setMonthNumber={setMonthNumber}
          header={header}
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: '56rem',
      }}>
      {renderCalendar()}
    </Box>
  );
};

export default StaticCalendar;
