import React, { FunctionComponent } from 'react';
import { FlexBox } from '../../../utils/styledComponents';
import { Box, useTheme } from '@mui/system';
import { formatDateDMY } from '../../../utils/utils';
import { Typography } from '@mui/material';
import { colorPallete, naturalPallete, primary } from '../../../theme';
import { useNavigate } from 'react-router';
import { TypographyGrey } from '../../Admin/Applications/ApplicationsListView';
import AccessLevelTagCard from '../../common/AccessLevelTagCard';
import { BookingStatsDto, EcoUserBasicDto } from '../../../utils/models';

// export enum PendingType {
//   Pending = 'pending',
//   Accepted = 'accepted',
//   Rejected = 'rejected',
// }
//
// interface PendingStatus {
//   name: PendingType;
//   color: string;
//   type?: string;
// }

interface ApplicationListElementProps {
  bookingData: BookingStatsDto;
}

const BookingAnalyticsElement: FunctionComponent<
  ApplicationListElementProps
> = (props) => {
  const { bookingData } = props;
  const navigate = useNavigate();
  const theme = useTheme();

  function renderUser(user: EcoUserBasicDto) {
    return (
      <FlexBox
        sx={{
          alignItems: 'start',
          gap: '0.625rem',
        }}>
        <img
          src={user.profilePic}
          style={{
            width: '3.75rem',
            height: '3.75rem',
            objectFit: 'cover',
            borderRadius: '100%',
          }}
          alt='Profile picture'
        />

        <FlexBox
          sx={{
            flexDirection: 'column',
            alignItems: 'start',
            gap: '0.625rem',
          }}>
          <AccessLevelTagCard
            accessLevelName={user.accessLevelName}
            accessLevelColor={user.accessLevelColor}
            height={'1.25rem'}
          />

          <FlexBox>
            <Typography
              sx={{
                lineHeight: '1rem',
                fontWeight: '600',
              }}>
              {user.fullName}
            </Typography>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    );
  }

  return (
    <FlexBox
      className={'between'}
      sx={{
        width: '100%',
        paddingY: '1rem',
        borderBottom: `1px solid ${naturalPallete.natural6}`,
      }}>
      <FlexBox
        sx={{
          gap: '1.875rem',
        }}>
        <FlexBox
          sx={{
            width: '14.688rem',
            paddingLeft: '1.875rem',
          }}>
          {renderUser(bookingData.bookingUser)}
        </FlexBox>

        <FlexBox
          sx={{
            width: '20.313rem',
          }}>
          {renderUser(bookingData.bookedUser)}
        </FlexBox>

        <Box
          sx={{
            width: '12.5rem',
          }}>
          <Typography
            align={'center'}
            sx={{
              width: '5rem',
              fontSize: '0.8rem',
              fontWeight: '400',
              paddingX: '0.75rem',
              paddingY: '0.125rem',
              color: bookingData.pending
                ? naturalPallete.natural2
                : bookingData.accepted
                ? colorPallete.additionalDarkGreen
                : colorPallete.warningRed,
              borderRadius: '6px',
              backgroundColor: primary.whiteHighlight,
              display: 'inline-block',
            }}>
            {bookingData.pending
              ? 'Pending'
              : bookingData.accepted
              ? 'Accepted'
              : 'Declined'}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '6rem',
          }}>
          <TypographyGrey>
            {formatDateDMY(new Date(bookingData.date), '.')}
          </TypographyGrey>
        </Box>

        <Box>
          <Typography
            align={'center'}
            sx={{
              color: theme.palette.primary.dark,
              bgcolor: colorPallete.additionalGreen,
              border: `1px solid ${colorPallete.additionalDarkGreen}`,
              borderRadius: '4px',
              paddingY: 0.3,
              paddingX: 1.2,
              width: '8.25rem',
            }}>
            {`${bookingData.startTime.slice(
              0,
              5,
            )} - ${bookingData.endTime.slice(0, 5)}`}
          </Typography>
        </Box>
      </FlexBox>
    </FlexBox>
  );
};

export default BookingAnalyticsElement;
