import React, { FunctionComponent, useState } from 'react';
import { CometChat, Group } from '@cometchat/chat-sdk-javascript';
import { FlexBox } from '../../utils/styledComponents';
import {
  CometChatConversations,
  CometChatGroups,
  CometChatMessages,
  CometChatUsers,
  ConversationsStyle,
  GroupsStyle,
  MessagesStyle,
  UsersStyle,
} from '@cometchat/chat-uikit-react';
import Button, { ButtonProps } from '@mui/material/Button';
import { useTheme } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import AddIcon from '@mui/icons-material/Add';
import PasswordMenu from './PasswordMenu';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateGroup from './CreateGroup';
import AddMembers from './AddMembers';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { fetchUnreadMessageCount } from '../../utils/utils';
import { naturalPallete } from '../../theme';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

interface StyledButtonProps extends ButtonProps {
  isActive: boolean;
}

const StyledButton = styled(Button)<StyledButtonProps>(
  ({ theme, isActive }) => ({
    fontWeight: 700,
    borderRadius: '0',
    minWidth: '6.66rem',
    width: '6,66rem',
    backgroundColor: isActive
      ? theme.palette.primary.dark
      : theme.palette.primary.contrastText,
    color: isActive ? theme.palette.common.white : theme.palette.common.black,
    ':hover': {
      backgroundColor: isActive
        ? theme.palette.primary.dark
        : theme.palette.primary.contrastText,
    },
  }),
);

interface ChatUiKitProps {
  setUnreadMessagesCount: React.Dispatch<React.SetStateAction<number>>;
  setIsChatExpanded: (value: boolean) => void;
  currEcosystem: string | null;
  chatKey: number;
}

const ChatUiKit: FunctionComponent<ChatUiKitProps> = (props) => {
  const { setUnreadMessagesCount, setIsChatExpanded, currEcosystem, chatKey } =
    props;
  const [activeUserChat, setActiveUserChat] = useState<CometChat.User>();
  const [activeGroupChat, setActiveGroupChat] = useState<CometChat.Group>();
  const [isPasswordMenuActive, setIsPasswordMenuActive] = useState(false);
  const [groupListKey, setGroupListKey] = useState(0);
  const [conversationsKey, setConversationsKey] = useState(0);
  const [activeTab, setActiveTab] =
    useState<keyof typeof sideWindow>('conversations');
  const theme = useTheme();

  const getOnItemClick = (conversation: CometChat.Conversation) => {
    setIsChatExpanded(true);
    const lastMessage = conversation.getLastMessage();

    if (lastMessage) {
      const messageId = lastMessage.getId();
      const senderId = lastMessage.getSender().getUid();

      if (conversation.getConversationType() === 'user') {
        const user = conversation.getConversationWith() as CometChat.User;
        setActiveUserChat(user);
        setActiveGroupChat(undefined);

        if (conversation.getUnreadMessageCount()) {
          const receiverId = user.getUid();
          const receiverType = CometChat.RECEIVER_TYPE.USER;
          CometChat.markAsRead(
            messageId,
            receiverId,
            receiverType,
            senderId,
          ).then(() => {
            fetchUnreadMessageCount(setUnreadMessagesCount);
          });
        }
      } else {
        const group = conversation.getConversationWith() as CometChat.Group;
        setActiveGroupChat(group);
        setActiveUserChat(undefined);

        if (conversation.getUnreadMessageCount()) {
          const receiverId = group.getGuid();
          const receiverType = CometChat.RECEIVER_TYPE.GROUP;
          CometChat.markAsRead(
            messageId,
            receiverId,
            receiverType,
            senderId,
          ).then(() => {
            fetchUnreadMessageCount(setUnreadMessagesCount);
          });
        }
      }
    } else {
      if (conversation.getConversationType() === 'user') {
        const user = conversation.getConversationWith() as CometChat.User;
        setActiveUserChat(user);
        setActiveGroupChat(undefined);
      } else {
        const group = conversation.getConversationWith() as CometChat.Group;
        setActiveGroupChat(group);
        setActiveUserChat(undefined);
      }
    }
  };

  function handleOnGroupItemClick(group: CometChat.Group): void {
    if (group.getHasJoined()) {
      setActiveGroupChat(group);
    } else {
      if (group.getType() === 'password') {
        setActiveGroupChat(group);
        setIsChatExpanded(true);
        setIsPasswordMenuActive(true);
      } else {
        CometChat.joinGroup(group.getGuid()).then((group) => {
          setGroupListKey((prevKey) => prevKey + 1);
          setIsChatExpanded(true);
          setActiveGroupChat(group);
        });
      }
    }
  }

  function handleLeaveGroup(group: CometChat.Group) {
    CometChat.leaveGroup(group.getGuid()).then(() => {
      setGroupListKey((prevKey) => prevKey + 1);
      setConversationsKey((prevKey) => prevKey + 1);
    });
    setActiveGroupChat(undefined);
  }

  function handleDeleteGroup(group: CometChat.Group) {
    CometChat.deleteGroup(group.getGuid()).then(() => {
      setGroupListKey((prevKey) => prevKey + 1);
      setConversationsKey((prevKey) => prevKey + 1);
    });
    setActiveGroupChat(undefined);
  }

  function handleOnUserClick(user: CometChat.User): void {
    setIsChatExpanded(true);
    setActiveUserChat(user);
  }

  const messagesMenu = () => {
    return (
      <>
        {activeGroupChat && activeGroupChat.getScope() !== 'admin' && (
          <Button
            title={'Leave group'}
            onClick={() =>
              handleLeaveGroup(activeGroupChat as CometChat.Group)
            }>
            <LogoutIcon />
          </Button>
        )}

        {activeGroupChat && activeGroupChat.getScope() === 'admin' && (
          <FlexBox>
            <Button
              title={'Delete group'}
              onClick={() => setActiveTab('addUsers')}>
              <AddCircleIcon />
            </Button>
            <Button
              title={'Delete group'}
              onClick={() =>
                handleDeleteGroup(activeGroupChat as CometChat.Group)
              }>
              <DeleteIcon />
            </Button>
          </FlexBox>
        )}
        <Button
          title={'Close window'}
          onClick={() => {
            setActiveUserChat(undefined);
            setActiveGroupChat(undefined);
          }}>
          <CloseIcon />
        </Button>
      </>
    );
  };

  function usersTabMenu() {
    return (
      <Button
        title={'Create a group'}
        onClick={() => setActiveTab('createGroup')}>
        <AddIcon />
      </Button>
    );
  }

  function conversationsTabMenu() {
    return (
      <Button title={'Create a chat'} onClick={() => setActiveTab('users')}>
        <img
          src={`${process.env.PUBLIC_URL}/images/icons/new_chat.svg`}
          alt='new chat icon'
        />
      </Button>
    );
  }

  const stylesForSideWindow = {
    border: `1px solid ${naturalPallete.natural6}`,
    borderRadius:
      activeUserChat || activeGroupChat ? '0 10px 0 0' : '10px 10px 0 0',
    background: naturalPallete.pureWhite,
  };

  const conversationsStyle = new ConversationsStyle({ ...stylesForSideWindow });
  const usersStyle = new UsersStyle({ ...stylesForSideWindow });
  const groupsStyle = new GroupsStyle({ ...stylesForSideWindow });

  const sideWindow = {
    conversations: (
      <CometChatConversations
        key={`${chatKey}-${conversationsKey}`}
        onItemClick={getOnItemClick}
        title={'Chats'}
        conversationsStyle={conversationsStyle}
        menus={conversationsTabMenu()}
      />
    ),
    groups: (
      <CometChatGroups
        menus={usersTabMenu()}
        onItemClick={handleOnGroupItemClick}
        key={groupListKey}
        groupsRequestBuilder={new CometChat.GroupsRequestBuilder()
          .setTags([currEcosystem as string])
          .setLimit(50)}
        groupsStyle={groupsStyle}
      />
    ),
    users: (
      <CometChatUsers
        usersRequestBuilder={new CometChat.UsersRequestBuilder()
          .setTags([currEcosystem as string])
          .setLimit(50)}
        onItemClick={handleOnUserClick}
        usersStyle={usersStyle}
      />
    ),
    createGroup: (
      <CreateGroup
        currEcosystem={currEcosystem ?? ''}
        setActiveTab={setActiveTab}
      />
    ),
    addUsers: (
      <AddMembers
        currEcosystem={currEcosystem ?? ''}
        setActiveTab={setActiveTab}
        group={activeGroupChat as CometChat.Group}
      />
    ),
  };

  const messagesStyle = new MessagesStyle({
    background: naturalPallete.pureWhite,
    borderRadius: '10px 0 0 10px',
    border: `1px solid ${naturalPallete.natural6}`,
  });

  return (
    <>
      {isPasswordMenuActive ? (
        <PasswordMenu
          setIsPasswordMenuActive={setIsPasswordMenuActive}
          group={activeGroupChat as Group}
          setActiveGroupChat={setActiveGroupChat}
          sx={{
            right: '-35rem',
          }}
        />
      ) : (
        <FlexBox
          sx={{
            height: '30rem',
            width: '100%',
          }}>
          <FlexBox
            sx={{
              flexGrow: 1,
              height: '100%',
            }}>
            {(activeGroupChat || activeUserChat) && (
              <CometChatMessages
                user={activeUserChat && activeUserChat}
                group={activeGroupChat && activeGroupChat}
                hideDetails={true}
                auxiliaryMenu={messagesMenu()}
                messagesStyle={messagesStyle}
              />
            )}
          </FlexBox>
          <FlexBox
            sx={{
              flexDirection: 'column',
              height: '100%',
              width: '20rem',
            }}>
            {sideWindow[activeTab]}
            <FlexBox>
              <StyledButton
                isActive={activeTab === 'conversations'}
                onClick={() => setActiveTab('conversations')}>
                Chats
              </StyledButton>
              <StyledButton
                isActive={activeTab === 'groups'}
                onClick={() => setActiveTab('groups')}>
                Groups
              </StyledButton>
              <StyledButton
                isActive={activeTab === 'users'}
                onClick={() => setActiveTab('users')}>
                Users
              </StyledButton>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}
    </>
  );
};

export default ChatUiKit;
