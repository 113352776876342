import { Card, SxProps, Typography, useTheme } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { acterioTheme } from 'theme';
import { FlexBox } from '../../utils/styledComponents';
import { Box } from '@mui/system';

interface IDateTag {
  date?: string;
  width?: number | string;
  height?: number | string;
  fontSizeMonth?: number | string;
  fontSizeDay?: number | string;
  sx?: SxProps;
  isAdditionalDataVisible?: boolean;
  startTime?: string;
  endTime?: string;
}

const DateTag: FunctionComponent<IDateTag> = (props) => {
  const {
    date = '',
    width,
    height,
    fontSizeMonth,
    fontSizeDay,
    sx,
    isAdditionalDataVisible = false,
    startTime,
    endTime,
  } = props;
  const theme = useTheme();

  const dateObject = new Date(date);
  const day = dateObject.getDate();
  const month = dateObject
    .toLocaleString('en-us', { month: 'short' })
    .toUpperCase();

  return (
    <Card
      sx={{
        minWidth: width ?? '50px',
        height: height ?? '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: acterioTheme.pureWhite,
        ...sx,
      }}
      elevation={2}>
      <FlexBox
        sx={{
          paddingLeft: isAdditionalDataVisible ? '1rem' : '0',
        }}>
        <FlexBox
          sx={{
            gap: '1rem',
          }}>
          <Box>
            <Typography
              sx={{
                fontSize: fontSizeMonth ?? 12,
                fontWeight: 700,
                color: theme.palette.primary.main,
                textTransform: 'lowercase',
                '&::first-letter': {
                  textTransform: 'capitalize',
                },
              }}>
              {month}
            </Typography>
            <Typography
              sx={{
                fontSize: fontSizeDay ?? 16,
                fontWeight: 'bold',
              }}>
              {day}
            </Typography>
          </Box>
          {isAdditionalDataVisible && (
            <FlexBox
              sx={{
                width: '9rem',
                justifyContent: 'center',
                gap: '0.5rem',
              }}>
              <Typography variant={'h6'}>{startTime as string}</Typography>-
              <Typography variant={'h6'}>{endTime as string}</Typography>
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </Card>
  );
};

export default DateTag;
