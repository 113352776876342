import React, { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material';
import {
  ModeRounded,
  ControlPointDuplicate,
  DeleteRounded,
} from '@mui/icons-material';
import { acterioTheme } from 'theme';
import { IEvent, IListButton } from 'utils/models';
import { getEcosystemInfo, getStoredEvents } from 'utils/storage';
import { trackUmamiEvent } from 'utils/utils';
import { useEventsContext } from 'services/contexts/Events/EventsContext';
import useDeleteEvent from 'hooks/Events/useDeleteEvent';
import BasicCard from '../../../common/BasicCard';
import ConfirmationForm from '../../../common/ConfirmationForm';

interface GridPostProps {
  event: IEvent;
  isHome?: boolean;
  handleClose: () => void;
}

const GridPost: FunctionComponent<GridPostProps> = (props) => {
  const { event, isHome, handleClose } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const deleteProps = { event, setIsLoading, handleClose };
  const deleteEvent = useDeleteEvent({ ...deleteProps });
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(
    null,
  );
  const [isShowDeleteConfirm, setIsShowDeleteConfirm] =
    useState<boolean>(false);

  const theme = useTheme();
  const buttonList: IListButton[] = [
    {
      name: 'Edit',
      onClick: () => callShowEditEvent(false),
      icon: ModeRounded,
    },
    {
      name: 'Duplicate',
      onClick: () => callShowEditEvent(true),
      icon: ControlPointDuplicate,
    },
    {
      name: 'Delete',
      onClick: openConfrimDelte,
      icon: DeleteRounded,
      textColor: acterioTheme.warningRed,
    },
  ];

  const {
    setIsShowEventDetails,
    setChosenEvent,
    setIsShowEdit,
    setIsDuplicate,
  } = useEventsContext();

  function callShowEventDetails() {
    setIsShowEventDetails(true);
    setChosenEvent(event);
    trackUmamiEvent('View Event Details');
  }

  function callShowEditEvent(isDuplicate: boolean) {
    setIsShowEdit(true);
    setChosenEvent(event);
    setPopoverAnchorEl(null);
    if (!isDuplicate) return;
    setIsDuplicate(true);
  }

  function openConfrimDelte() {
    setIsShowDeleteConfirm(true);
    setPopoverAnchorEl(null);
  }

  const storageEvents = getStoredEvents();
  const storedEvent =
    storageEvents && storageEvents.find((e) => e.id === event.id);

  const eventAttendingList = storedEvent?.attendees || event.attendees;
  const eventAttendingListLength = eventAttendingList?.length;

  const isAbleToEdit = getEcosystemInfo().accessLevel?.manageEvents;

  return (
    <BasicCard
      typeOfContent={'event'}
      title={event.title}
      showDetails={() => callShowEventDetails()}
      tags={event.tags}
      canEdit={event.canUserEdit}
      buttonList={buttonList}
      isHome={isHome}
      description={event.description}
      location={event.location}
      multiDay={event.multiDay}
      time={event.time}
      startTime={event.startTime}
      endTime={event.endTime}
      startDate={event.startDate}
      startDateString={event.startDateString}
      endDateString={event.endDateString}
      numberOfAttendees={eventAttendingListLength}
      isShowDeleteConfirm={isShowDeleteConfirm}
      photo={event.photo}
      deleteConfirmationForm={
        <ConfirmationForm
          isOpen={isShowDeleteConfirm}
          handleClose={() => {
            setIsShowDeleteConfirm(false);
          }}
          funcExe={deleteEvent}
          text={'to delete this post?'}
          isLoading={isLoading}
          isWarning
        />
      }
    />
  );
};

export default GridPost;
