import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { FunctionComponent } from 'react';
import Room from './Room';
import { IBookedRoom } from 'utils/models';
import { FlexBox } from '../../../utils/styledComponents';

interface BookedRoomsListProps {
  rooms: IBookedRoom[];
  setRooms: (rooms: any) => void;
}

const BookedRoomsList: FunctionComponent<BookedRoomsListProps> = (props) => {
  const { rooms, setRooms } = props;

  function groupRoomsByMonth(
    bookedRooms: IBookedRoom[],
  ): Record<string, IBookedRoom[]> {
    return bookedRooms.reduce((acc, room) => {
      const roomDate = new Date(room.date);
      const monthYear = roomDate.toLocaleString('en-us', {
        month: 'long',
        year: 'numeric',
      });
      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }

      acc[monthYear].push(room);

      return acc;
    }, {} as Record<string, IBookedRoom[]>);
  }

  const groupedRooms = groupRoomsByMonth(rooms);

  return (
    <Box
      sx={{
        bgcolor: 'inherit',
        padding: 0,
        margin: 2,
        borderRadius: 0.2,
        width: '100%',
      }}>
      <Box marginTop={-2} marginLeft={-2}>
        {Object.entries(groupedRooms).map(([monthYear, rooms]) => (
          <Box key={monthYear}>
            <FlexBox
              sx={{
                gap: '1rem',
              }}>
              <Typography variant={'h4'}>{monthYear}</Typography>
              <Divider
                sx={{
                  height: 'calc(100% - 0.5em)',
                  width: '84%',
                  marginTop: '-0.5em',
                }}
              />
            </FlexBox>
            <FlexBox
              sx={{
                gap: '1.188rem',
                flexWrap: 'wrap',
              }}>
              {rooms.map((room) => (
                <Room key={room.id} room={room} setRooms={setRooms} />
              ))}
            </FlexBox>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default BookedRoomsList;
