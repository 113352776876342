import { Box } from '@mui/system';
import React, { FunctionComponent, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Typography, useTheme } from '@mui/material';
import { DeleteRounded, Error } from '@mui/icons-material';
import { acterioTheme, primary } from 'theme';
import { IBookedRoom, IListButton } from 'utils/models';
import RoomDetails from '../RoomBooking/RoomDetails';
import { ALERT_TYPES, AlertContext } from 'components/Alert/AlertContext';
import { BOOKING_ROOM_UPCOMING, deleteData } from 'utils/requests';
import ConfirmationForm from 'components/common/ConfirmationForm';
import BasicCard from '../../common/BasicCard';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InformationModal from '../../common/Modal/InformationModal';

interface RoomProps {
  room: IBookedRoom;
  setRooms: (rooms: any) => void;
}

const Room: FunctionComponent<RoomProps> = (props) => {
  const { room, setRooms } = props;
  const { ecosystemName } = useParams();
  const navigate = useNavigate();

  const theme = useTheme();

  const { addAlert } = useContext(AlertContext);

  const [showMeetingDetails, setShowMeetingDetails] = useState<boolean>(false);
  const [isShowConfirmForm, setIsShowConfirmForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowDeleteConfirm, setIsShowDeleteConfirm] =
    useState<boolean>(false);

  function showDetails() {
    setShowMeetingDetails(true);
  }

  function cancelBooking() {
    setShowMeetingDetails(false);
    setIsShowConfirmForm(true);
  }

  function openConfirmDeleteModal() {
    setIsShowDeleteConfirm(true);
  }

  const buttonList: IListButton[] = [
    {
      name: 'Delete',
      onClick: openConfirmDeleteModal,
      icon: DeleteRounded,
      textColor: acterioTheme.warningRed,
    },
  ];

  async function requestCancelBooking() {
    setIsLoading(true);

    try {
      await deleteData(BOOKING_ROOM_UPCOMING, [
        { name: 'ecosystemName', value: ecosystemName },
        { name: 'id', value: room.id },
      ]);

      setRooms((prevMeetings: IBookedRoom[]) =>
        prevMeetings.filter((m) => m.id !== room.id),
      );
      setShowMeetingDetails(false);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Booking canceled successfully',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setIsLoading(false);
  }

  return (
    <Box
      sx={{
        maxWidth: '25.813rem',
        marginY: 2,
        display: 'flex',
        justifyContent: 'space-between',
      }}>
      <BasicCard
        title={room.title}
        typeOfContent={'booking'}
        buttonList={buttonList}
        isTagsVisible={false}
        isDetailsVisible={false}
        isAdditionalTimeAtTheTop={true}
        location={room.location}
        startTime={room.startTime}
        endTime={room.endTime}
        startDate={room.date}
        photo={room.picture}
        numberOfAttendees={room.capacity}
        isButtonVisible={true}
        buttonIcon={<VisibilityIcon />}
        buttonText={'View room'}
        onButtonClick={showDetails}
        isShowDeleteConfirm={isShowDeleteConfirm}
        deleteConfirmationForm={
          <InformationModal
            isOpen={isShowDeleteConfirm}
            handleClose={() => setIsShowDeleteConfirm(false)}
            primaryText='Confirm'
            primaryOnClick={requestCancelBooking}
            primaryBgColor={primary.warningRed}
            primaryBgHoverColor={primary.warningPlaceholder}
            secondaryText='Cancel'
            secondaryOnClick={() => setIsShowDeleteConfirm(false)}
            headerText={'Are you sure you want to cancel this booking?'}
            buttonSx={{
              '&:hover': {
                backgroundColor: primary.warningHover,
                color: primary.warningRed,
              },
            }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex' }}>
                <Error
                  sx={{
                    marginRight: '0.25rem',
                    color: primary.warningRed,
                    width: '2rem',
                  }}
                />
                <Typography sx={{ mb: '1.25rem' }}>
                  Deleting the facility booking is permanent. Please confirm
                  your choice carefully.
                </Typography>
              </Box>
            </Box>
          </InformationModal>
        }
      />
      {showMeetingDetails && (
        <RoomDetails
          isOpen={showMeetingDetails}
          handleClose={() => {
            setShowMeetingDetails(false);
          }}
          room={room}
          isBooked={true}
          cancelBooking={cancelBooking}
        />
      )}
      {isShowConfirmForm && (
        <ConfirmationForm
          isOpen={isShowConfirmForm}
          handleClose={() => {
            setIsShowConfirmForm(false);
          }}
          funcExe={requestCancelBooking}
          text={'cancel this booking?'}
          isLoading={isLoading}
        />
      )}
    </Box>
  );
};

export default Room;
