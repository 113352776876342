import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import Home from './pages/Home/Home';
import Navbar from './components/Navbar/Navbar';
import Resources from './pages/Resources/Resources';
import Admin from './pages/Admin/Admin';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { white } from './theme';
import clsx from 'clsx';
import Member from './pages/Members/Member';
import Company from './pages/Companies/Company';
import Account from './pages/Account/Account';
import { renderToStaticMarkup } from 'react-dom/server';
import { chatEnabledSystems, getPathName } from './utils/utils';
import Dashboard from './pages/Dashboard/Dashboard';
import AdminManageAccess from './pages/Admin/AdminManageAccess';
import AdminManageTheme from './pages/Admin/AdminManageTheme';
import AdminManageUsers from './pages/Admin/AdminManageUsers';
import Login from './pages/Login/Login';
import { IAccount, IEcosystem } from './utils/models';
import {
  clearAllStorage,
  clearEcosystemData,
  clearStoredAppData,
  existsEcosystem,
  getAccount,
  getEcosystemInfo,
  getUser,
  removeEcosystem,
  removeEcosystemInfo,
  setEcosystem,
  setEcosystemInfo,
} from './utils/storage';
import {
  DASHBOARD_ROUTE,
  ECOSYSTEM_DATA_ROUTE,
  getData,
  updateRefreshTokens,
} from './utils/requests';
import { ALERT_TYPES, AlertContext } from './components/Alert/AlertContext';
import NotFound from './pages/NotFound/NotFound';
import AdminManageJourneys from './pages/Admin/AdminManageJourneys';
import JourneyTemplate from './components/Admin/ManageJourneys/JourneyTemplate/JourneyTemplate';
import AdminCustomCompanyProfiles from './pages/Admin/AdminCustomCompanyProfiles';
import CompanyTemplate from './components/Admin/ManageCustomCompanyProfiles/CompanyTemplate';
import SuperAdmin from './pages/SuperAdmin/SuperAdmin';
import Events from './pages/Events/Events';
import JourneyPage from './pages/Companies/JourneyPage';
import Booking from './pages/Booking/Booking';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import MobileNavbar from './components/Navbar/MobileNavbar';
import { CompanyProvider } from './services/contexts/Companies/CompanyContext';
import CalendarSyncRequest from './components/Booking/Availability/CalendarSyncRequest';
import { BookingMenuKeys } from './components/Booking/BookingSideMenu';
import { EventsProvider } from './services/contexts/Events/EventsContext';
import LoginBackground from './assests/LoginBackground';
import { useMobileContext } from './services/contexts/MobileContext';
import { Box } from '@mui/system';
import Community from './pages/Community/Community';
import ApplicationsPage from 'pages/Admin/ApplicationsPage';
import { ApplicationProvider } from 'services/contexts/Applications/ApplicationsContext';
import ApplicationForm from 'components/Admin/Applications/ApplicationForm';
import { useCustomTheme } from './services/contexts/Themes/ThemeContext';
import CompanyPdfDownload from 'pages/Companies/CompanyPdfDownload';
import StartAssessment from 'components/Admin/Applications/Assessment/StartAssessment';
import { AssessmentProvider } from 'services/contexts/Applications/AssessmentContext';
import { ApplicationMenuProvider } from 'services/contexts/Applications/ApplicationMenuContext';
import ApplicationSinglePdfDownload from 'components/Admin/Applications/ApplicationSinglePdfDownload';
import ApplicationsListPdfDownload from 'components/Admin/Applications/ApplicationsListPdfDownload';
import { UIKitSettingsBuilder } from '@cometchat/uikit-shared';
import { CometChatUIKit } from '@cometchat/chat-uikit-react';
import { CometChat } from '@cometchat/chat-sdk-javascript';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: white,
    position: 'relative',
  },
  main: {
    overflowY: 'auto',
    minHeight: (props: any) =>
      props.isMobile
        ? `calc(100vh - ${theme.mixins.toolbar.height} - 5rem)`
        : `calc(100vh - ${theme.mixins.toolbar.height})`,
    maxHeight: (props: any) =>
      props.isMobile
        ? `calc(100vh - ${theme.mixins.toolbar.height} - 5rem)`
        : `calc(100vh - ${theme.mixins.toolbar.height})`,
    marginTop: `${theme.mixins.toolbar.height}`,
    marginBottom: (props: any) => (props.isMobile ? '5rem' : 0),
    position: 'relative',
    '&::-webkit-scrollbar': {
      width: '0.7em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 12px #E0E0E0',
      webkitBoxShadow: 'inset 0 0 12px #E0E0E0',
      borderRadius: '0px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#BDBDBD',
      borderRadius: '0px',
    },
  },
  fadeIn: {
    animation: '1ms $fadeIn forwards',
  },
  fadeOut: {
    animation: '1ms $fadeOut forwards',
  },
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
      //transform: 'translate(-10px, 0)',
    },
    to: {
      opacity: 1,
      //transform: 'translate(0px, 0px)',
    },
  },
  '@keyframes fadeOut': {
    from: {
      opacity: 1,
      //transform: 'translate(0px, 0px)',
    },
    to: {
      opacity: 0,
      //transform: 'translate(-10px, 0)',
    },
  },
  backgroundImage: {
    backgroundImage: `url('data:image/svg+xml;utf8, ${encodeURIComponent(
      renderToStaticMarkup(<LoginBackground height={1080} width={1920} />),
    )}')`,
    backgroundSize: 'cover',
  },
  inherit: {
    backgroundColor: 'transparent !important',
  },
  auth: {
    minHeight: '100vh !important',
    maxHeight: '100vh !important',
    marginTop: '0 !important',
  },
}));

const SHOW_WAVES_BG_ON: string[] = [
  'login',
  'signup',
  'dashboard',
  'reset-password',
  'join-by-invite',
  'unsubscribe',
  'account',
];

const HIDE_NAVBAR_ON: string[] = [
  'login',
  'signup',
  'reset-password',
  'join-by-invite',
  'super-admin',
  'unsubscribe',
  'apply',
];

interface OwnProps {}

const App: FunctionComponent<OwnProps> = () => {
  const location = useLocation();
  const isLogin = location.pathname === '/login';

  const { isMobile } = useMobileContext();
  const { setCurrentTheme } = useCustomTheme();

  const classes = useStyles({ isLogin, isMobile });

  const appId = process.env.REACT_APP_COMET_CHAT_APP_ID as string;
  const region = process.env.REACT_APP_COMET_CHAT_REGION as string;
  const authKey = process.env.REACT_APP_COMET_CHAT_AUTH_KEY as string;

  const navigate = useNavigate();
  const authenticated = !!getUser();
  const { addAlert } = useContext(AlertContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [currEcosystem, setCurrEcosystem] = useState<string | null>(null);
  const [chatKey, setChatKey] = useState<number>(0);
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransitionStage] = useState('fadeIn');
  const [showBg, setShowBg] = useState<boolean>(false);
  const [clearedCache, setClearedCache] = useState<boolean>(false);

  const [isChatInitialised, setIsChatInitialised] = useState<boolean>(false);
  const [ensureAccessLevelExist, setEnsureAccessLevelExist] =
    useState<boolean>(false);

  const mainRef = useRef<HTMLDivElement | null>(null);
  const hideNavbar =
    HIDE_NAVBAR_ON.includes(getPathName(displayLocation.pathname)) ||
    displayLocation.pathname.endsWith('/pdf');

  useEffect(() => {
    clearStoredAppData();

    if (!authenticated) {
      const fromActerioPath = HIDE_NAVBAR_ON.includes(
        getPathName(displayLocation.pathname),
      );
      clearAllStorage();
      if (!fromActerioPath) {
        navigate('/login');
      }
    }

    setClearedCache(true);
  }, []);

  useEffect(() => {
    const noAnimOn = location.pathname === '/account';
    const fromActerioPath = SHOW_WAVES_BG_ON.includes(
      getPathName(displayLocation.pathname),
    );

    if (location.pathname !== displayLocation.pathname) {
      const isCommunity =
        location.pathname.endsWith('companies') ||
        location.pathname.endsWith('members');

      if (noAnimOn || isCommunity) {
        setTransitionStage('fadeIn');
        setDisplayLocation(location);
      } else {
        setTransitionStage('fadeOut');
      }
    }
    const pathName = getPathName(location.pathname);
    const isActerioPath = SHOW_WAVES_BG_ON.includes(pathName);
    setShowBg(isActerioPath);

    if (location.pathname.split('/').includes('ecosystem') && !currEcosystem) {
      const ecosystemName = location.pathname.split('/')[2];
      clearEcosystemData();
      getEcosystem(ecosystemName);
    } else if (isActerioPath && currEcosystem) {
      setCurrEcosystem(null);
      removeEcosystem();
      removeEcosystemInfo();
    }

    if (isActerioPath && !noAnimOn) {
      setDisplayLocation(location);
    }
  }, [location]);

  const initializeChat = async () => {
    const UIKitSettings = new UIKitSettingsBuilder()
      .setAppId(appId)
      .setRegion(region)
      .setAuthKey(authKey)
      .subscribePresenceForAllUsers()
      .build();

    try {
      await CometChatUIKit.init(UIKitSettings);
      console.log('init successful');
      setIsChatInitialised(true);
    } catch (error) {
      console.log('Error initializing CometChatUIKit:', error);
    }
  };

  // Function to handle the login
  const handleLogin = async (currentUser: IAccount) => {
    try {
      const user = await CometChatUIKit.login(String(currentUser.userId));
      console.log('login successful');

      // if (
      //   user.getAvatar() !== currentUser.profilePicture ||
      //   user.getName() !== currentUser.fullName ||
      //   user.getTags()[0] !== currEcosystem
      // ) {
      //   const updatedUser = new CometChat.User(user.getUid());
      //   updatedUser.setName(currentUser.fullName);
      //   updatedUser.setAvatar(currentUser.profilePicture);
      //   if (currEcosystem) {
      //     updatedUser.setTags([currEcosystem]);
      //   }

      //   try {
      //     const updatedUserResponse = await CometChat.updateUser(
      //       updatedUser,
      //       authKey,
      //     );
      //     console.log('User updated successfully:', updatedUserResponse);
      //   } catch (error) {
      //     console.log('Error updating user:', error);
      //   }
      // }
    } catch (error: any) {
      console.log('Error during login:', error);

      if (
        currEcosystem &&
        error.code === 'ERR_UID_NOT_FOUND' &&
        chatEnabledSystems.includes(currEcosystem)
      ) {
        const newUserObj = {
          uid: String(currentUser.userId),
          name: currentUser.fullName,
          avatar: currentUser.profilePicture,
          tags: [currEcosystem],
        };

        const newUser = new CometChat.User(newUserObj);
        try {
          const createdUser = await CometChatUIKit.createUser(newUser);
          console.log('New user created successfully:', createdUser);

          await CometChatUIKit.login(String(currentUser.userId));
          console.log('login successful after user creation');
          setChatKey((prev) => prev + 1);
        } catch (createError) {
          console.log('Error creating or logging in user:', createError);
        }
      }
    }
  };

  // Main useEffect hook
  useEffect(() => {
    const currentUser = getAccount();

    // Initialize the chat
    initializeChat();

    // Handle the login
    if (isChatInitialised) {
      handleLogin(currentUser);
    }
  }, [currEcosystem, isChatInitialised]);

  async function getEcosystem(ecosystemName: string) {
    setLoading(true);

    try {
      const ecoInfo = await getData(ECOSYSTEM_DATA_ROUTE, [
        {
          name: 'ecosystemName',
          value: ecosystemName,
        },
      ]);
      if (setCurrentTheme) {
        setCurrentTheme(ecoInfo.colorPalette);
      }
      const data = await getData(DASHBOARD_ROUTE);
      const ecosystems: IEcosystem[] = data.ecosystems;
      const ecosystem = ecosystems.find(
        (eco) => eco.name === decodeURIComponent(ecosystemName),
      );
      setEcosystemInfo(ecoInfo);
      setEcosystem(ecosystem as IEcosystem);
      setCurrEcosystem(decodeURI(ecosystemName));
      setEnsureAccessLevelExist(!!existsEcosystem());
    } catch (e: any) {
      updateRefreshTokens();
      console.error('error', e);
      navigate(authenticated ? 'dashboard' : 'login');
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message,
      });
    }

    setLoading(false);
  }

  function withEventsContext(children: any) {
    return <EventsProvider>{children}</EventsProvider>;
  }

  function withApplicationsContext(children: any) {
    return <ApplicationProvider>{children}</ApplicationProvider>;
  }

  function withAssessmentContext(children: any) {
    return <AssessmentProvider>{children}</AssessmentProvider>;
  }

  function withApplicationMenuContext(children: any) {
    return <ApplicationMenuProvider>{children}</ApplicationMenuProvider>;
  }

  function renderBookingRoutes() {
    return (
      <>
        <Route
          path={'/ecosystem/:ecosystemName/booking'}
          element={<Booking />}
        />
        <Route
          path={'/ecosystem/:ecosystemName/booking/people/book/:id?'}
          element={<Booking defaultMenuOption={BookingMenuKeys.People} />}
        />
        <Route
          path={'/ecosystem/:ecosystemName/booking/rooms/book'}
          element={<Booking defaultMenuOption={BookingMenuKeys.Rooms} />}
        />
        <Route
          path={'/ecosystem/:ecosystemName/booking/people/upcoming'}
          element={
            <Booking defaultMenuOption={BookingMenuKeys.People_Bookings} />
          }
        />
        <Route
          path={'/ecosystem/:ecosystemName/booking/rooms/upcoming'}
          element={
            <Booking defaultMenuOption={BookingMenuKeys.Room_Bookings} />
          }
        />
        <Route
          path={'/ecosystem/:ecosystemName/booking/people/requests'}
          element={<Booking defaultMenuOption={BookingMenuKeys.Requests} />}
        />
        <Route
          path={'/ecosystem/:ecosystemName/booking/people/availability'}
          element={
            <Booking defaultMenuOption={BookingMenuKeys.People_Availability} />
          }
        />
        <Route
          path={'/ecosystem/:ecosystemName/booking/rooms/manage'}
          element={<Booking defaultMenuOption={BookingMenuKeys.Manage_Rooms} />}
        />
        <Route
          path={'/ecosystem/:ecosystemName/booking/rooms/availability'}
          element={
            <Booking defaultMenuOption={BookingMenuKeys.Room_Availability} />
          }
        />
        <Route
          path={'/ecosystem/:ecosystemName/booking/rooms/analytics'}
          element={<Booking defaultMenuOption={BookingMenuKeys.Analytics} />}
        />
      </>
    );
  }

  function renderAdminRoutes() {
    return (
      <>
        <Route path={'/ecosystem/:ecosystemName/admin'} element={<Admin />} />
        <Route
          path={'/ecosystem/:ecosystemName/admin/access'}
          element={<AdminManageAccess />}
        />
        <Route
          path={'/ecosystem/:ecosystemName/admin/theme'}
          element={<AdminManageTheme />}
        />
        <Route
          path={'/ecosystem/:ecosystemName/admin/users'}
          element={<AdminManageUsers />}
        />
        <Route
          path={'/ecosystem/:ecosystemName/admin/journeys'}
          element={<AdminManageJourneys />}
        />
        <Route
          path={'/ecosystem/:ecosystemName/admin/custom-company-profiles'}
          element={<AdminCustomCompanyProfiles />}
        />
        <Route
          path={'/ecosystem/:ecosystemName/admin/journeys/:journeyId'}
          element={<JourneyTemplate />}
        />
        <Route
          path={
            '/ecosystem/:ecosystemName/admin/custom-company-profiles/:companyTemplateId'
          }
          element={<CompanyTemplate />}
        />
        <Route
          path={
            '/ecosystem/:ecosystemName/admin/applications/:applicationProcessId?/:applicantId?'
          }
          element={withApplicationMenuContext(
            withApplicationsContext(
              withAssessmentContext(<ApplicationsPage />),
            ),
          )}
        />
        <Route
          path='/ecosystem/:ecosystemName/admin/applications/:applicationProcessId/:applicantId/pdf'
          element={<ApplicationSinglePdfDownload />}
        />
        <Route
          path='/ecosystem/:ecosystemName/admin/applications/:applicationProcessId/pdf'
          element={<ApplicationsListPdfDownload />}
        />
        <Route
          path='/ecosystem/:ecosystemName/admin/applications/:applicationProcessId/email/pdf'
          element={<ApplicationsListPdfDownload isEmailPDFMode={true} />}
        />
      </>
    );
  }

  function renderMembersRoutes() {
    return (
      <>
        <Route
          path={'/ecosystem/:ecosystemName/members'}
          element={<Community isMembers={true} />}
        />
        <Route
          path='/ecosystem/:ecosystemName/members/:profileOwnerId'
          element={<Member />}
        />
      </>
    );
  }

  function renderCompaniesRoutes() {
    return (
      <>
        <Route
          path={'/ecosystem/:ecosystemName/companies'}
          element={<Community isMembers={false} />}
        />
        <Route
          path='/ecosystem/:ecosystemName/companies/:companyId'
          element={
            <CompanyProvider>
              <Company />
            </CompanyProvider>
          }
        />
        <Route
          path='/ecosystem/:ecosystemName/companies/:companyId/pdf'
          element={<CompanyPdfDownload />}
        />
        <Route
          path='/ecosystem/:ecosystemName/companies/:companyId/journey'
          element={<JourneyPage />}
        />
      </>
    );
  }

  function renderActerioRoutes() {
    return (
      <>
        <Route
          path={'/booking/calendar/sync'}
          element={<CalendarSyncRequest />}
        />
        <Route
          path={'/reset-password'}
          element={<Login formToDisplay={'password-reset-request-email'} />}
        />
        <Route
          path={'/reset-password/:passwordResetKey'}
          element={<Login formToDisplay={'password-reset'} />}
        />
        <Route
          path={'/join-by-invite/:secretKey'}
          element={<Login formToDisplay={'join-by-invite'} />}
        />
        <Route
          path={'/login'}
          element={
            authenticated ? (
              <Navigate to={'/dashboard'} />
            ) : (
              <Login formToDisplay={'login'} />
            )
          }
        />
        <Route
          path={'/unsubscribe/:userId'}
          element={<Login formToDisplay={'unsubscribe'} />}
        />
        <Route path={'/super-admin'} element={<SuperAdmin />} />
        <Route
          path={'/signup'}
          element={
            authenticated ? (
              <Navigate to={'/dashboard'} />
            ) : (
              <Login formToDisplay={'signup'} />
            )
          }
        />
        <Route
          path={'/apply/applications/:formId'}
          element={<ApplicationForm />}
        />
        <Route
          path={'/apply/assessment/:hashedId'}
          element={<StartAssessment />}
        />
        {authenticated && (
          <>
            <Route path={'/account'} element={<Account />} />
            <Route path={'/dashboard'} element={<Dashboard />} />
          </>
        )}
      </>
    );
  }

  function renderEcosystemRoutes() {
    return (
      <>
        <Route
          path={'/ecosystem/:ecosystemName'}
          element={withEventsContext(<Home main={mainRef.current} />)}
        />
        <Route
          path={'/ecosystem/:ecosystemName/events'}
          element={withEventsContext(<Events />)}
        />
        <Route
          path={'/ecosystem/:ecosystemName/resources'}
          element={<Resources />}
        />
        {renderBookingRoutes()}
        {renderMembersRoutes()}
        {renderCompaniesRoutes()}
        {renderAdminRoutes()}
        <Route path='*' element={<NotFound />} />
      </>
    );
  }

  function renderRoutes() {
    if (clearedCache) {
      return (
        <>
          {renderActerioRoutes()}
          {authenticated && ensureAccessLevelExist && renderEcosystemRoutes()}
        </>
      );
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div
        style={{ position: 'absolute', width: '100vw', height: '100vh' }}
        className={showBg ? classes.backgroundImage : classes.root}>
        {!hideNavbar && (
          <Navbar
            isChatInitialised={isChatInitialised}
            currEcosystem={currEcosystem}
            chatKey={chatKey}
            main={mainRef.current}
            showBorder={showBg}
            loading={loading}
            ecoLogo={
              existsEcosystem()
                ? getEcosystemInfo().ecosystemNavBarLogo
                : undefined
            }
          />
        )}
        <main
          ref={mainRef}
          className={clsx(classes.main, {
            [classes.auth]: hideNavbar,
            [classes.fadeIn]: !showBg && transitionStage === 'fadeIn',
            [classes.fadeOut]: !showBg && transitionStage === 'fadeOut',
            [classes.inherit]: showBg,
          })}
          style={{ backgroundColor: showBg ? 'transparent' : white }}
          onAnimationEnd={() => {
            if (transitionStage === 'fadeOut') {
              setTransitionStage('fadeIn');
              setDisplayLocation(location);
            }
          }}>
          <Box
            sx={{
              width: '100%',
              margin: '0 auto',
              overflow: 'hidden',
              minHeight: '85vh',
              padding: '3rem 0.3rem',
              paddingBottom: 0,
            }}>
            <Routes location={displayLocation}>
              <Route
                path={'/'}
                element={
                  <Navigate
                    to={
                      authenticated
                        ? '/dashboard'
                        : location.pathname === '/unsubscribe'
                        ? '/unsubscribe'
                        : '/login'
                    }
                  />
                }
              />
              {renderRoutes()}
            </Routes>
          </Box>
        </main>
        {isMobile && !hideNavbar && <MobileNavbar />}
      </div>
    </LocalizationProvider>
  );
};

export default App;
