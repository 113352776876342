import React, { FunctionComponent, useEffect, useState } from 'react';
import { IconButton, Typography, Theme, useTheme } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { getMonth } from 'utils/utils';
import BookingMonth from './BookingMonth';
import dayjs from 'dayjs';
import { IAvailability, IAvailableTimeslots } from 'utils/models';
import { FlexBox } from '../../../utils/styledComponents';

const useStyles = makeStyles((theme: Theme) => ({
  placeholder: {},
}));

interface CalendarViewProps {
  availability: IAvailability | IAvailableTimeslots[];
  handleHourDateClick: (selectedDay: Date) => void;
  selectedDate?: Date;
  setSelectedDate: (date: Date) => void;
  monthIndex: number;
  setMonthIndex: (n: number) => void;
  monthNumber: number;
  setMonthNumber: (n: number) => void;
  currentYear: number;
  setCurrentYear: (n: number) => void;
  header?: string;
}

const BookingCalendar: FunctionComponent<CalendarViewProps> = (props) => {
  const {
    availability,
    handleHourDateClick,
    selectedDate,
    setSelectedDate,
    monthIndex,
    setMonthIndex,
    monthNumber,
    setMonthNumber,
    currentYear,
    setCurrentYear,
    header,
  } = props;
  const theme = useTheme();

  const [calendarDays, setCaledarDays] = useState<any>(getMonth());

  useEffect(() => {
    setCaledarDays(getMonth(monthIndex));
  }, [monthIndex]);

  if (monthNumber < 0) {
    setMonthNumber(11);
    setCurrentYear(currentYear - 1);
  } else if (monthNumber > 11) {
    setMonthNumber(0);
    setCurrentYear(currentYear + 1);
  }

  function handlePreviousMonth() {
    setMonthIndex(monthIndex - 1);
    setMonthNumber(monthNumber - 1);
  }

  function handleNextMonth() {
    setMonthIndex(monthIndex + 1);
    setMonthNumber(monthNumber + 1);
  }

  function renderHeader() {
    return (
      <FlexBox
        sx={{
          backgroundColor: '#FFFFFF',
          justifyContent: 'space-between',
          borderRadius: '12px',
          height: '5.625rem',
          width: '100%',
          padding: '0 1rem 0 3rem',
        }}>
        <Typography variant={'h4'}>{header}</Typography>

        <FlexBox
          sx={{
            justifyContent: 'space-evenly',
          }}>
          <IconButton
            onClick={handlePreviousMonth}
            sx={{
              padding: '0.25rem',
              '&:hover': { bgcolor: theme.palette.primary.light },
            }}>
            <ChevronLeft sx={{ color: theme.palette.primary.main }} />
          </IconButton>

          <Typography width={'8rem'} variant='h6' sx={{ textAlign: 'center' }}>
            {`${dayjs().month(monthIndex).format('MMMM')} ${currentYear}`}
          </Typography>

          <IconButton
            onClick={handleNextMonth}
            sx={{
              padding: '0.25rem',
              '&:hover': { bgcolor: theme.palette.primary.light },
            }}>
            <ChevronRight sx={{ color: theme.palette.primary.main }} />
          </IconButton>
        </FlexBox>
      </FlexBox>
    );
  }

  return (
    <Box
      sx={{
        margin: '0 2rem 2rem 2rem',
        width: '100%',
      }}>
      {renderHeader()}
      <Box
        sx={{
          width: '100%',
          minHeight: '26.813rem',
          backgroundColor: '#FFFFFF',
          padding: '0.2rem 1rem 1.5rem 1rem',
          borderRadius: '12px',
          marginTop: '0.3rem',
        }}>
        <BookingMonth
          calendarDays={calendarDays}
          monthIndex={monthIndex}
          availability={availability}
          handleHourDateClick={handleHourDateClick}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </Box>
    </Box>
  );
};

export default BookingCalendar;
