import React, { FunctionComponent, useState } from 'react';
import { IListButton, ITag } from '../../utils/models';
import {
  Box,
  ButtonBase,
  Card,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import MoreOptionsOverlay from './MoreOptionsButton/MoreOptionsOverlay';
import MoreOptionsButtons from './MoreOptionsButton/MoreOptionsButtons';
import TagDisplay from './Tags/TagDisplay';
import RichTextDisplay from './RichTextDisplay';
import {
  CalendarMonthOutlined,
  LocationOn,
  PeopleAlt,
  WatchLater,
} from '@mui/icons-material';
import TypographyBox from './TypographyBox';
import TimezoneOffsetDisplay from './TimezoneOffsetDisplay';
import DateTag from '../Events/DateTag';
import { naturalPallete } from '../../theme';
import { useMobileContext } from '../../services/contexts/MobileContext';
import { defaultEventPhoto } from '../../utils/utils';
import CommonButton from './CommonButton';
import { FlexBox } from '../../utils/styledComponents';

interface BasicCardProps {
  title: string;
  canEdit?: boolean;
  isHome?: boolean;
  showDetails?: () => void;
  buttonList?: IListButton[];
  isTagsVisible?: boolean;
  tags?: ITag[];
  isDetailsVisible?: boolean;
  description?: string;
  location?: string;
  isAdditionalTimeAtTheTop?: boolean;
  multiDay?: boolean;
  time?: string;
  startTime?: string;
  endTime?: string;
  startDate?: string;
  startDateString?: string;
  endDateString?: string;
  numberOfAttendees?: number;
  isShowDeleteConfirm?: boolean;
  deleteConfirmationForm?: React.ReactNode;
  photo?: string;
  typeOfContent: 'event' | 'booking';
  isButtonVisible?: boolean;
  buttonText?: string;
  buttonIcon?: React.ReactNode;
  onButtonClick?: () => void;
}

const BasicCard: FunctionComponent<BasicCardProps> = (props) => {
  const {
    title,
    canEdit,
    isHome,
    showDetails,
    buttonList = [],
    isTagsVisible = true,
    tags,
    isDetailsVisible = true,
    description,
    location = '',
    isAdditionalTimeAtTheTop = false,
    multiDay,
    startTime = '',
    endTime = '',
    startDateString = '',
    endDateString = '',
    time,
    numberOfAttendees,
    isShowDeleteConfirm,
    deleteConfirmationForm,
    photo,
    startDate,
    typeOfContent,
    isButtonVisible = false,
    buttonText,
    buttonIcon,
    onButtonClick,
  } = props;

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(
    null,
  );

  const { isMobile } = useMobileContext();
  const theme = useTheme();

  function RenderTextGrid() {
    const titleTags: ITag[] = [
      {
        name: title,
      },
    ];

    return (
      <Grid
        item
        container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          cursor: 'pointer',
          padding: '0.5rem 0.5rem 1rem 0.5rem',
        }}>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography
            onClick={showDetails}
            variant='h4'
            sx={{
              marginLeft: 1,
              textAlign: 'left',
            }}>
            {title}
          </Typography>

          {((typeOfContent === 'event' && !isHome && canEdit) || true) && (
            <MoreOptionsOverlay
              popoverAnchorEl={popoverAnchorEl}
              setPopoverAnchorEl={setPopoverAnchorEl}>
              <MoreOptionsButtons buttonList={buttonList} />
            </MoreOptionsOverlay>
          )}
        </Box>

        {isTagsVisible && (
          <Box
            onClick={showDetails}
            display='flex'
            alignItems='center'
            marginLeft={0.5}
            sx={{
              width: '100%',
            }}>
            <TagDisplay
              tags={!!tags?.length ? tags : titleTags}
              oneRow={isHome}
            />
          </Box>
        )}

        {isDetailsVisible && (
          <Box
            onClick={showDetails}
            display={'flex'}
            flexDirection={'column'}
            marginBottom={0}
            flex={1}>
            {!isMobile && !!description && (
              <RichTextDisplay
                style={{
                  variant: 'body1',
                  paddingX: '0.5rem',
                  marginBottom: '1.5rem',
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  marginLeft: '0.25rem',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  maxHeight: '4rem',
                  lineHeight: '1.5rem',
                  wordWrap: 'break-word',
                }}
                text={description}
              />
            )}
          </Box>
        )}
        <FlexBox
          onClick={showDetails}
          sx={{
            flexDirection: 'column',
            alignItems: 'start',
            marginBottom: 0,
          }}>
          {!location && (
            <Box
              sx={{
                height: '1.5rem',
              }}
            />
          )}
          {location !== '' && location !== null && (
            <FlexBox
              sx={{
                marginTop: '0.5rem',
                marginLeft: '0.5rem',
                gap: '0.5rem',
              }}>
              <LocationOn
                sx={{
                  color: theme.palette.primary.main,
                  marginLeft: 0.2,
                  marginRight: 0.5,
                  fontSize: '1.375rem',
                }}
              />
              <TypographyBox text={location} numberOfLines={2} />
            </FlexBox>
          )}

          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'>
            {!isAdditionalTimeAtTheTop && (
              <Box display='flex' marginY={1} marginLeft={1} width={'100%'}>
                {multiDay ? (
                  <CalendarMonthOutlined
                    sx={{
                      color: theme.palette.primary.main,
                      marginLeft: 0.2,
                      marginRight: 0.5,
                      fontSize: '1.375rem',
                    }}
                  />
                ) : (
                  <WatchLater
                    sx={{
                      color: theme.palette.primary.main,
                      marginLeft: 0.2,
                      marginRight: 0.5,
                      fontSize: '1.375rem',
                    }}
                  />
                )}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                  }}>
                  <Typography
                    sx={{
                      overflow: 'hidden',
                    }}>
                    {multiDay
                      ? `${startTime ? startTime : ''} ${startDateString} - ${
                          endTime ? endTime : ''
                        } ${endDateString}`
                      : time ?? `${startTime} - ${endTime}`}
                  </Typography>
                  {!multiDay && <TimezoneOffsetDisplay />}
                </Box>
              </Box>
            )}

            <Box
              display='flex'
              marginRight={1}
              marginLeft={1}
              alignItems={'center'}>
              <PeopleAlt
                sx={{
                  color: theme.palette.primary.main,
                  marginLeft: 0.2,
                  marginRight: 0.5,
                  fontSize: '1.375rem',
                }}
              />
              <Typography
                sx={{
                  marginLeft: 1,
                }}
                variant={typeOfContent === 'booking' ? 'h6' : 'body1'}>
                {numberOfAttendees}{' '}
                {typeOfContent === 'booking' ? 'people' : ''}
              </Typography>
            </Box>
          </Box>
          {isButtonVisible && (
            <FlexBox
              sx={{
                width: '100%',
                justifyContent: 'end',
                paddingRight: '1.5rem',
              }}>
              <CommonButton
                text={buttonText}
                icon={buttonIcon}
                onClick={onButtonClick}
              />
            </FlexBox>
          )}
        </FlexBox>
        {isShowDeleteConfirm && deleteConfirmationForm}
      </Grid>
    );
  }

  function RenderPhotoGrid() {
    return (
      <Grid
        item
        sx={{
          height:
            typeOfContent === 'booking'
              ? '14.375rem'
              : isMobile
              ? '6.563rem'
              : isHome
              ? '9.375rem'
              : '9.375rem',
        }}>
        <ButtonBase
          onClick={showDetails}
          sx={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}>
          <img
            src={photo ? photo : defaultEventPhoto}
            alt='Event'
            style={{
              width: '100%',
              height: 'auto',
              minWidth: '100%',
              minHeight: '100%',
              objectFit: 'cover',
            }}
          />
          <DateTag
            date={startDate}
            sx={{ position: 'absolute', top: '1rem', left: '1rem' }}
            isAdditionalDataVisible={isAdditionalTimeAtTheTop}
            startTime={startTime}
            endTime={endTime}
          />
        </ButtonBase>
      </Grid>
    );
  }

  return (
    <Card
      sx={{
        border: `1px solid ${naturalPallete.natural8}`,
        width: '100%',
        height: '100%',
        borderRadius: 2,
        bgcolor: 'white',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      }}>
      {RenderPhotoGrid()}
      {RenderTextGrid()}
    </Card>
  );
};

export default BasicCard;
